.container {
	min-height: 100vh;
	background-image: url("/assets/login/bg@2x.webp");
	background-size: cover;
	background-position: center;

	.content {
		width: 358px;
		margin-left: auto;
		margin-right: 160px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.title {
			margin-top: 140px;
			font-size: 32px;
			color: #333333;
			line-height: 40px;
			text-align: center;
			font-style: normal;
		}

		.loginContainer {
			width: 358px;
			height: 382px;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			background: #FFFFFF;
			box-shadow: 0 2px 16px 0 rgba(5, 32, 50, 0.06);
			border-radius: 4px;

			.subtitle {
				margin-top: 64px;
				font-weight: 600;
				font-size: 20px;
				color: #333333;
				line-height: 28px;
				text-align: left;
				font-style: normal;
			}

			.formContainer {
				width: 358px;
				margin-top: 20px;
			}
		}
	}
}
