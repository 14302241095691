.container {
	height: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;

	.filterContainer {
		width: 100%;
		padding: 15px 24px;
		display: flex;
		flex-direction: column;
		background: #FFFFFF;
	}

	.tableContainer {
		width: 100%;
		flex: 1;
		margin-top: 16px;
		background: #FFFFFF;
	}
}
