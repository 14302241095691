.container {
	height: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;

	.searchContainer {
		width: 100%;
		padding: 15px 24px;
		display: flex;
		flex-direction: column;
		background: #FFFFFF;
	}

	.tableContainer {
		width: 100%;
		flex: 1;
		margin-top: 16px;
		background: #FFFFFF;
	}
}

.customerType {
	width: 84px;
	height: 22px;
	border-radius: 4px;

	display: flex;
	justify-content: center;
	align-items: center;

	span {
		font-size: 12px;
		line-height: 20px;
		text-align: left;
		font-style: normal;
	}
}

.customerTypeS {
	@extend .customerType;
	background: #FFF1F0;
	border: 1px solid #FFCCC7;

	span {
		color: #FF4D4F;
	}
}

.customerTypeA {
	@extend .customerType;
	background: #FFFBE6;
	border: 1px solid #FFF1B8;

	span {
		color: #FAAD14;
	}
}

.customerTypeB {
	@extend .customerType;
	background: #E6F4FF;
	border: 1px solid #BAE0FF;

	span {
		color: #1677FF;
	}
}

.customerTypeC {
	@extend .customerType;
	background: #F6FFED;
	border: 1px solid #D9F7BE;

	span {
		color: #52C41A;
	}
}

.customerTypeD {
	@extend .customerType;
	background: rgba(0, 0, 0, 0.04);
	border: 1px solid #D9D9D9;

	span {
		color: #000000;
	}
}

.cooperationStatus {
	display: flex;
	align-items: center;

	.dot {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		margin-right: 8px;
	}

	.dotPending {
		@extend .dot;
		background-color: #0055E3;
	}

	.dotFailed {
		@extend .dot;
		background-color: #E5484D;
	}

	.dotNoBC {
		@extend .dot;
		background-color: #FB9A0E;
	}

	.dotNoCharge {
		@extend .dot;
		background-color: #FB9A0E;
	}

	.dotNoSpend {
		@extend .dot;
		background-color: #FB9A0E;
	}

	.dotSpending {
		@extend .dot;
		background-color: #0055E3;
	}

	.dotSpendAbort {
		@extend .dot;
		background-color: #E5484D;
	}

	.dotEnd {
		@extend .dot;
		background-color: #30A46C;
	}
}

.bizStatus {
	display: flex;
	align-items: center;

	.dot {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		margin-right: 8px;
	}

	.dotPending {
		@extend .dot;
		background-color: #999999;
	}

	.dotStarted {
		@extend .dot;
		background-color: #0055E3;
	}

	.dotPause {
		@extend .dot;
		background-color: #E5484D;
	}
}
