.content {
	height: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;

	.customer {
		width: 100%;
		height: 170px;
		padding: 15px 24px;
		display: flex;
		flex-direction: column;
		background: #FFFFFF;

		.data {
			margin-top: 23px;
			display: flex;

			.dataItem {
				width: 33%;
				display: flex;
				flex-direction: column;
				align-items: center;

				.dataTitle {
					height: 20px;
					font-weight: 400;
					font-size: 14px;
					color: #666666;
					line-height: 20px;
					text-align: center;
					font-style: normal;
				}

				.count {
					height: 49px;
					font-weight: bold;
					font-size: 40px;
					color: #333333;
					line-height: 49px;
					text-align: left;
					font-style: normal;
				}
			}
		}
	}

	.chart {
		width: 100%;
		flex: 1;
		margin-top: 16px;
		padding: 15px 24px;
		background: #FFFFFF;

		.data {
			display: flex;
			justify-content: center;

			.pieContainer {
				margin-top: 60px;
			}
		}
	}
}

.cardHeader {
	height: 32px;
	display: flex;

	.cardTitle {
		height: 22px;
		display: flex;
		align-items: center;

		.block {
			width: 4px;
			height: 16px;
			background: #0055E3;
			border-radius: 1px;
		}

		.cardTitleText {
			margin-left: 6px;
			font-size: 16px;
			color: rgba(0, 0, 0, 0.85);
			line-height: 22px;
			font-weight: 500;
		}
	}

	.filter {
		margin-left: auto;
	}
}
