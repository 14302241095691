.menuHeader {
	width: 208px;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: #FFFFFF;
	background: #0055E3;
	line-height: 24px;
	text-align: center;
	font-style: normal;
}

.menuContainer {
	margin-top: 12px
}

.header {
	height: 100%;
	display: flex;
	align-items: center;

	.title {
		width: 56px;
		height: 20px;
		margin-left: 16px;
		font-weight: 400;
		font-size: 14px;
		color: #333333;
		line-height: 20px;
	}

	.logout {
		margin-left: auto;
	}

	.user {
		height: 48px;
		margin-left: 12px;
		margin-right: 16px;
		display: flex;
		flex-direction: column;

		.username {
			height: 22px;
			color: #333333;
			margin-top: 4px;
			font-size: 14px;
			line-height: 22px;
		}

		.role {
			height: 17px;
			color: #666666;
			font-size: 12px;
			line-height: 17px;
		}
	}
}
