.container {
	height: 400px;
	overflow: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	.regionTitle {
		height: 22px;
		margin-bottom: 20px;
		font-weight: 500;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 22px;
		text-align: left;
		font-style: normal;
	}
}

.addIcon {
	color: blue;
	margin-left: 8px;
}

.addIconNotAllowed {
	color: blue;
	opacity: 0.5;
	margin-left: 8px;

	&:hover {
		cursor: not-allowed;
	}
}

.deleteIcon {
	color: gray;
	margin-left: 8px;
}

.deleteIconNotAllowed {
	color: gray;
	opacity: 0.5;
	margin-left: 8px;

	&:hover {
		cursor: not-allowed;
	}
}
